import React, { useEffect, useState } from "react";
import "./MainContent.css";
import FadeInContainer from "../../components/FadeInContainer/FadeInContainer.tsx";

const MainContent = ({ onDiscordClick }) => {
  const [devicePlatform, setDevicePlatform] = useState<"desktop" | "android" | "ios">("desktop");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setDevicePlatform("android");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDevicePlatform("ios");
    } else {
      setDevicePlatform("desktop");
    }
  }, []);

  return (
    <FadeInContainer>
      <div className="main-container">
        <div className="left-container">
          <h1 className="header">AlgoBytes</h1>
          <p className="description">
            Supercharge your coding skills with interactive lessons and challenges that teach you Data Structures and
            Algorithms. Master LeetCode problems effortlessly with our active recall based learning experience.
          </p>
          <p className="launch-date">
                Launching Spring 2025!
              </p>

          <div className="button-container">
            
            {/* <div className="app-store-button-container">

              {devicePlatform === "desktop" && (
                <>
                  <img src="/GooglePlayButton.png" alt="Google Play Button" className="button" />
                  <img src="/AppleStoreButton.svg" alt="Apple Store button" className="button" />
                </>
              )}
              {devicePlatform === "android" && (
                <img src="/GooglePlayButton.png" alt="Google Play Button" className="single-button" />
              )}
              {devicePlatform === "ios" && (
                <img src="/AppleStoreButton.svg" alt="Apple Store Button" className="single-button" />
              )}
            </div> */}
            <img src="/DiscordLogo.png" alt="Discord Logo Button" className="button" onClick={() => onDiscordClick()} />
          </div>
        </div>
        <div className="right-container">
          <div className="glow-container">
            <div className="phone-image-container">
            <img
              src="/AppDemo2Phone.png"
              className="phone-image"
              alt="App Demo"
            />
            </div>
          </div>
        </div>
      </div>
    </FadeInContainer>
  );
};

export default MainContent;
